import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceCacheKey, invoicesService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { ErrorItem, useForm, useToast } from '@hofy/ui';

export interface CreditInvoiceFormData {
    reason: string;
    creditLineIds: UUID[];
}

const emptyCreditInvoiceFormData = {
    reason: '',
    creditLineIds: [],
};

export const useCreditInvoiceForm = (invoiceId: UUID, onClose: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (values: CreditInvoiceFormData) => invoicesService.creditDeelInvoice(invoiceId, values),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceCacheKey, invoiceId] });
            showToast({
                type: 'positive',
                message: 'Invoice credited scheduled.',
            });
            onClose();
        },
    });

    const validateReason = (reason: string): ErrorItem => {
        if (!reason) {
            return 'Reason is required';
        }
        return reason.length > 255 ? 'Reason must be less than 255 characters' : undefined;
    };

    const form = useForm<CreditInvoiceFormData, CreditInvoiceFormData>({
        initial: emptyCreditInvoiceFormData,
        validate: ({ reason, creditLineIds }) => ({
            reason: validateReason(reason),
            creditLineIds: !creditLineIds.length ? 'At least one line is required' : undefined,
        }),
        onSubmit: data => mutation.mutate(data),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
