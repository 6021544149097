import { xor } from 'lodash-es';
import React from 'react';

import { emptyInvoiceEntriesFilters, useInvoiceEntries } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import {
    AsyncButton,
    FormTextarea,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph2,
} from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useCreditInvoiceForm } from '../../../../../store/invoices/useCreditInvoiceForm';
import { CreditInvoiceEntriesTable } from './CreditInvoiceEntriesTable';

interface CreditInvoiceModalProps {
    invoiceId: UUID;
    onClose(): void;
}

export const CreditInvoiceModal: React.FC<CreditInvoiceModalProps> = ({ invoiceId, onClose }) => {
    const { form, isLoading, isError } = useCreditInvoiceForm(invoiceId, onClose);

    const {
        invoiceEntries,
        isLoading: entriesAreLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useInvoiceEntries({
        ...emptyInvoiceEntriesFilters,
        invoice: invoiceId,
    });

    return (
        <Modal onClose={onClose} width={1200}>
            <ModalHeader title='Credit Deel invoice' />
            <ModalContent>
                <FormTextarea
                    label='Reason'
                    api={form.fields.reason}
                    placeholder='Enter the reason for crediting this invoice.'
                    isRequired
                />
                <Paragraph2>Toggle invoice entry to credit by clickin on the row</Paragraph2>
                <CreditInvoiceEntriesTable
                    entries={invoiceEntries}
                    infinityScroll={{
                        hasMore: hasNextPage,
                        isLoading: entriesAreLoading,
                        isLoadingMore: isFetchingNextPage,
                        loadMore: fetchNextPage,
                    }}
                    onRowClick={entry =>
                        form.fields.creditLineIds.setValue(xor(form.values.creditLineIds, [entry.id]))
                    }
                    selected={form.fields.creditLineIds.value}
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Credit'
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                />
            </ModalFooter>
        </Modal>
    );
};
