import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceCacheKey, invoicesService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useForm, useToast } from '@hofy/ui';

interface DisableDunningFormData {
    reason: string;
}

const emptyDisableDunningFormData = {
    reason: '',
};

export const useExcludeFromDunning = (invoiceId: UUID, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (reason: string) => invoicesService.excludeFromDunning(invoiceId, reason),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceCacheKey, invoiceId] });
            showToast({
                type: 'positive',
                message: 'Invoice excluded from reminders successfully.',
            });
            onSuccess();
        },
    });

    const form = useForm<DisableDunningFormData, DisableDunningFormData>({
        initial: emptyDisableDunningFormData,
        validate: ({ reason }) => ({
            reason: !reason ? 'Reason is required' : undefined,
        }),
        onSubmit: data => mutation.mutate(data.reason),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
