import React from 'react';

import { InvoiceEntryDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import {
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIllustration,
    Tooltip,
} from '@hofy/ui';

import { SubsidiaryCell } from '../../../../../components/domain/contracts/SubsidiaryCell';
import { InvoiceEntryTypeChip } from '../../../invoiceEntries/components/InvoiceEntryTypeChip';

interface CreditInvoiceEntriesTableProps {
    entries: InvoiceEntryDto[];
    infinityScroll: InfiniteScrollConfig;
    onRowClick(entry: InvoiceEntryDto): void;
    selected: UUID[];
}

export const CreditInvoiceEntriesTable: React.FC<CreditInvoiceEntriesTableProps> = ({
    entries,
    infinityScroll,
    onRowClick,
    selected,
}) => {
    const { formatPrice } = usePrice();

    return (
        <InfinityScrollTable
            data={entries}
            toKey={entry => entry.id}
            infinityScroll={infinityScroll}
            onRowClick={onRowClick}
            emptyContent={
                <Placeholder illustration={SvgIllustration.FinanceSearch} title='No invoice entries' />
            }
            rowStyle={entry =>
                selected.includes(entry.id) ? { backgroundColor: Color.BackgroundSubtlePositive } : {}
            }
            minWidth={800}
            columns={[
                {
                    id: 'ref',
                    header: 'Ref',
                    width: 120,
                    flexGrow: 0,
                    renderer: entry => `#${entry.ref}`,
                },
                {
                    id: 'type',
                    header: 'Type',
                    flexGrow: 0,
                    flexShrink: 0,
                    width: 160,
                    renderer: entry => <InvoiceEntryTypeChip status={entry.type} />,
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 0,
                    width: 180,
                    renderer: entry => <SubsidiaryCell entry={entry} />,
                },
                {
                    id: 'description',
                    header: 'Description',
                    flexGrow: 1,
                    renderer: entry => (
                        <Tooltip body={entry.description} interactive asChild>
                            <Paragraph3 color={Color.ContentPrimary} textClomp={2}>
                                {entry.description}
                            </Paragraph3>
                        </Tooltip>
                    ),
                },
                {
                    id: 'price',
                    header: 'Price',
                    flexGrow: 0,
                    width: 140,
                    renderer: entry => `${entry.quantity} x ${formatPrice(entry.price)}`,
                },
            ]}
        />
    );
};
